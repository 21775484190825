import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { Spinner, LiveChat } from 'bv-components';
import { withModalWindow } from 'bv-hocs';
import { useHistory, useParams } from 'react-router';
import { t } from 'bv-i18n';
import {
  fetchPasswordRules as fetchPasswordRulesDucks,
  submitForm as submitFormDucks,
} from 'SharedComponents/password/ducks';
import { successHandler } from 'SharedComponents/password/helpers/handlers';
import getUrlParameter from '../../utils';
import { updateCodeValue as updateCodeValueDucks } from '../../ducks/send_sms_code_duck';
import Form from './form_redux';
import ErrorModal from '../error_modal';

const App = ({
  canInitForm, formIsSubmitting, fetchPasswordRules, updateCodeValue, submitForm,
  passwordRules,
}) => {
  useEffect(() => {
    fetchPasswordRules();

    return () => updateCodeValue('');
  }, []);

  const [response, setResponse] = useState(undefined);
  const history = useHistory();

  const tokenFromURL = useParams().token || getUrlParameter('token') || null;

  if (!canInitForm || formIsSubmitting) return <Spinner />;

  const onSubmit = (params) => submitForm({
    endpoint: '/account/change_password_from_code',
    params,
    onSuccess: (success) => setResponse({ ...success }),
    onError: (error) => setResponse({ ...error, success: false }),
  });

  return (
    <>
      <Form
        onSubmit={onSubmit}
        passwordRules={passwordRules}
        token={tokenFromURL}
      />
      <LiveChat className="live-chat live-chat--password_change" location="Password Reset" />
      {response?.success && successHandler(() => { history.push('/login'); })}
      {response?.success === false && (
        <ErrorModal
          message={response?.errors?.base?.[0] || t('javascript.account.reset_password.generic_error')}
          onCloseClick={() => setResponse(undefined)}
        />
      )}
    </>
  );
};

App.propTypes = {
  fetchPasswordRules: PropTypes.func.isRequired,
  passwordRules: PropTypes.instanceOf(Object).isRequired,
  canInitForm: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  updateCodeValue: PropTypes.func.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  passwordRules: state.codePasswordChange.passwordRules,
  canInitForm: state.codePasswordChange.canInitForm,
  formIsSubmitting: state.codePasswordChange.formIsSubmitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPasswordRules: compose(dispatch, fetchPasswordRulesDucks),
  submitForm: (params) => compose(dispatch(submitFormDucks(params))),
  updateCodeValue: compose(dispatch, updateCodeValueDucks),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withModalWindow('change_password')(App),
);
