import { apiResetPassword } from 'SharedComponents/password/api';
import { updateUserValue } from './send_sms_code_duck';

export const REQUEST_PASSWORD_RESET_INIT = 'passwordReset/start/REQUEST_PASSWORD_RESET_INIT';
export const REQUEST_PASSWORD_RESET_COMPLETE = 'passwordReset/start/REQUEST_PASSWORD_RESET_COMPLETE';

const initialState = {
  passwordResetIsRequesting: false,
};

export const requestPasswordResetInit = () => ({
  type: REQUEST_PASSWORD_RESET_INIT,
});

export const requestPasswordResetComplete = () => ({
  type: REQUEST_PASSWORD_RESET_COMPLETE,
});

export const requestPasswordReset = (params, endpoint, onSuccess, onError) => (dispatch) => {
  const user = params.email_or_username.trim();

  dispatch(requestPasswordResetInit());

  apiResetPassword(endpoint, user)
    .then((data) => {
      dispatch(requestPasswordResetComplete());
      dispatch(updateUserValue(user));
      onSuccess(data);
    }).catch(() => {
      dispatch(requestPasswordResetComplete());
      onError();
    });
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_PASSWORD_RESET_INIT:
      return { passwordResetIsRequesting: true };
    case REQUEST_PASSWORD_RESET_COMPLETE:
      return { passwordResetIsRequesting: false };
    default:
      return state;
  }
};
