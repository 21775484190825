import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { Form, Field } from 'react-final-form';
import { t } from 'bv-i18n';

import PasswordWithErrorList from 'SharedComponents/password_with_error_list';
import Password from 'SharedComponents/password/components/password';
import BrandedModalHeader from 'SharedComponents/password/components/branded_modal_header';
import {
  passwordConfirmationValidations,
} from 'SharedComponents/password/helpers/validations';

const FormView = ({
  code, user, token, onSubmit, passwordRules,
}) => (
  <div className="branded-modal password_change_wrapper">
    <BrandedModalHeader />
    <Form
      initialValues={{ code, user, token }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, invalid }) => (
        <form className="branded-modal__body" onSubmit={handleSubmit}>
          <ul>
            <li className="account-form__field">
              <PasswordWithErrorList
                label={t('javascript.account.reset_password.new_password')}
                name="password"
                type="password"
                rules={passwordRules}
                isChangePassword
              />
            </li>
            <li className="account-form__field">
              <Field
                name="password_confirmation"
                formName="codePasswordChange"
                component={Password}
                validate={passwordConfirmationValidations}
                placeholder={t('javascript.account.reset_password.retype_new_password')}
                withToggle
                showError={({ visited, dirty, error }) => visited && dirty && error}
              />
            </li>
            <Button
              secondary
              className="full-width"
              label={t('javascript.account.reset_password.change_password')}
              disabled={invalid}
            />
          </ul>
        </form>
      )}
    </Form>
  </div>
);

FormView.propTypes = {
  code: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  passwordRules: PropTypes.instanceOf(Object).isRequired,
};

export default FormView;
