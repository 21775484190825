import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { setFragment } from 'bv-helpers/location';

const LoginLink = () => (
  <div className="back-to-login-button-wrapper">
    <Button
      id="login_button"
      label={t('javascript.back_to_login')}
      onClick={() => setFragment('/login')}
      inverse
      secondary
    />
  </div>
);

export default LoginLink;
