import { v as bvVar } from 'bv';

const BrandedModalHeader = () => (
  <div className="branded-modal__header">
    <a className="branded-modal__title" href={bvVar('rootPath')}>
      <div className="bvs-site-logo__large" />
    </a>
  </div>
);

export default BrandedModalHeader;
