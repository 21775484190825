import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classnames from 'classnames';

const EmailOrUsername = ({ input, meta: { error, active, dirty } }) => {
  const inputFieldClasses = classnames('bvs-form-control', {
    active: active || input.value !== '',
    error: dirty && error,
  });

  return (
    <li className="bvs-form-group">
      <input
        {...input}
        type="text"
        className={inputFieldClasses}
        id="email-or-username"
      />
      <label htmlFor="email-or-username" className="bvs-form-control-placeholder">
        {t('javascript.account.reset_password.username_or_email')}
      </label>
      { dirty && error && <span className="bvs-form-error-msg">{error}</span> }
    </li>
  );
};

EmailOrUsername.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default EmailOrUsername;
