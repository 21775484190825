import PropTypes from 'prop-types';
import { LiveChat } from 'bv-components';
import { t } from 'bv-i18n';

import {
  IntroText,
  DotContainer,
  CodeInput,
  SendCodeSpinner,
  SendAgain,
  SendAgainControl,
  CodeStatus,
} from 'SharedComponents/send_code_from_sms';

import {
  DEFAULT,
  FAILED,
  WRONG_CODE,
  isValidStatus,
  isInvalidStatus,
} from '../../helpers/verification_statuses';

const SendSmsCodeView = ({
  loading, status, triesLeft, codeInputRef, inputValue, maxCodeLength, wasCodeResent, coolDown,
  code, resendLinkDisabled, focusCodeInput, handleCodeInputChange, handleCodeInputKeyPress,
  handleSendAgainButtonClick,
}) => (
  <>
    <div className="send-verify-code" onClick={focusCodeInput}>
      { loading && <SendCodeSpinner /> }
      <IntroText
        description={t('javascript.account.reset_password.email_and_or_sms_sent')}
        enterCode={t('javascript.account.reset_password.enter_verification_code')}
        showTrustCheckbox={false}
        icon={<div className="send-verify-code__header-icon" />}
      />
      <DotContainer status={status} code={code} />
      <CodeStatus
        status={status}
        triesLeft={triesLeft}
        isValidStatus={isValidStatus}
        isInvalidStatus={isInvalidStatus}
      />
      <CodeInput
        ref={codeInputRef}
        maxCodeLength={maxCodeLength}
        value={inputValue}
        handleCodeInputChange={handleCodeInputChange}
        handleCodeInputKeyPress={handleCodeInputKeyPress}
      />
      {
        [DEFAULT, FAILED, WRONG_CODE].includes(status) && (
          <SendAgain
            method={null}
            wasCodeResent={wasCodeResent}
            cooldown={coolDown}
            control={(
              <SendAgainControl
                disabled={resendLinkDisabled}
                handleClick={handleSendAgainButtonClick}
              />
            )}
          />
        )
      }
    </div>
    <LiveChat className="live-chat live-chat--password_change" location="Password Reset" />
  </>
);

SendSmsCodeView.propTypes = {
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  triesLeft: PropTypes.number.isRequired,
  codeInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  inputValue: PropTypes.string.isRequired,
  maxCodeLength: PropTypes.number.isRequired,
  wasCodeResent: PropTypes.bool.isRequired,
  coolDown: PropTypes.number.isRequired,
  resendLinkDisabled: PropTypes.bool.isRequired,
  focusCodeInput: PropTypes.func.isRequired,
  handleCodeInputChange: PropTypes.func.isRequired,
  handleCodeInputKeyPress: PropTypes.func.isRequired,
  handleSendAgainButtonClick: PropTypes.func.isRequired,
};

export default SendSmsCodeView;
