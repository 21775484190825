import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withModalWindow } from 'bv-hocs';
import { Spinner, LiveChat } from 'bv-components';
import { useHistory } from 'react-router-dom';
import { t } from 'bv-i18n';
import BrandedModalHeader from 'SharedComponents/password/components/branded_modal_header';
import Form from './form';
import { requestPasswordReset } from '../../ducks/start_duck';
import ErrorModal from '../error_modal';
import LoginLink from './login_link';

const App = ({ passwordResetIsRequesting, submitForm }) => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const history = useHistory();

  if (passwordResetIsRequesting) return <Spinner />;

  const onSuccess = (data) => {
    if (!data.errors.length) {
      history.push('/account/password_reset/send_sms_code');
    } else {
      setErrorMessage(data.errors[0]);
      setShowError(true);
    }
  };

  const onCloseClick = () => {
    setErrorMessage(undefined);
    setShowError(false);
  };

  return (
    <div className="branded-modal password_change_wrapper">
      <BrandedModalHeader />
      <Form
        onSubmit={submitForm('/account/reset_password', onSuccess, () => setShowError(true))}
      />
      <LoginLink />
      <LiveChat className="live-chat live-chat--password_change" location="Password Reset" />
      {showError && (
        <ErrorModal
          title={t('javascript.account.reset_password.generic_error')}
          message={errorMessage}
          onCloseClick={onCloseClick}
        />
      )}
    </div>
  );
};

App.propTypes = {
  passwordResetIsRequesting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  passwordResetIsRequesting: state.startPasswordReset.passwordResetIsRequesting,
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (api, onSuccess, onError) => (params) => dispatch(
    requestPasswordReset(params, api, onSuccess, onError),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withModalWindow('reset_your_password', { className: 'password-reset-modal' })(App),
);
