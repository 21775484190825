import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import {
  DEFAULT,
  FAILED,
  SUCCESS,
  CODE_WAS_SENT_TOO_MANY_TIMES,
  isValidStatus,
} from '../../helpers/verification_statuses';
import SendSmsCodeView from './send_sms_code_view';

const DEFAULT_COOLDOWN_VALUE = 60;
const BACKSPACE_KEY_CODE = 8;
const MAX_CODE_LENGTH = 6;

const SendSmsCodeContainer = ({
  code, user, loading, updateCodeValue, validatePasswordCode, resendPasswordCode,
}) => {
  const [status, setStatus] = useState(DEFAULT);
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [triesLeft, setTriesLeft] = useState(3);
  const [wasCodeResent, setWasCodeResent] = useState(false);
  const [resendLinkDisabled, setResendLinkDisabled] = useState(false);
  const [coolDown, setCoolDown] = useState(0);

  const codeInputRef = useRef();
  const { push } = useHistory();

  const focusCodeInput = () => {
    if (codeInputRef?.current) codeInputRef.current.focus();
  };

  useEffect(focusCodeInput);

  useEffect(() => updateCodeValue(''), []);

  useEffect(() => {
    if (!user) push('/account/password_reset');
  }, [user]);

  useEffect(() => {
    if (isValidStatus(status)) {
      setTimeout(() => push('/account/code_reset'), 1000);
    } else if (status === CODE_WAS_SENT_TOO_MANY_TIMES) {
      setTimeout(() => push('/login'), 1500);
    }
  }, [status]);

  const getFullCode = (value) => {
    if (!retrying) return value.slice(0, MAX_CODE_LENGTH);

    setStatus(DEFAULT);
    setRetrying(false);

    return value.slice(-1);
  };

  const handleCodeInputKeyPress = ({ keyCode }) => {
    if (keyCode !== BACKSPACE_KEY_CODE) return;

    if (code.length === MAX_CODE_LENGTH) {
      setStatus(DEFAULT);
      setInputValue('');
      updateCodeValue('');
    } else {
      setInputValue('');
      updateCodeValue(code.slice(0, -1));
    }
  };

  const handleCodeInputChange = ({ target: { value } }) => {
    if (disabled) return;

    const tmpValue = value.replace(/\D/g, '');
    const digit = tmpValue.slice(0, 1);
    const fullCode = getFullCode(`${code}${digit}`);

    setInputValue('');
    updateCodeValue(fullCode);

    if (fullCode.length === MAX_CODE_LENGTH) {
      setDisabled(true);

      validatePasswordCode({
        code: fullCode,
        user,
      }, (response) => {
        const newTriesLeft = triesLeft - 1;
        setTriesLeft(newTriesLeft);

        let newStatus;
        if (response.success) {
          newStatus = SUCCESS;
        } else {
          setDisabled(!newTriesLeft);
          if (newTriesLeft) {
            newStatus = response.error || FAILED;
            setRetrying(true);
          } else {
            newStatus = CODE_WAS_SENT_TOO_MANY_TIMES;
          }
        }
        setStatus(newStatus);
      });
    }
  };

  const handleSendAgainButtonClick = () => {
    resendPasswordCode(user, () => {
      setWasCodeResent(true);
      setResendLinkDisabled(true);
      let coolDownTime = DEFAULT_COOLDOWN_VALUE;
      setCoolDown(coolDownTime);

      const coolDownInterval = setInterval(() => {
        if (coolDownTime > 1) {
          coolDownTime -= 1;
          setCoolDown(coolDownTime);
        } else {
          clearInterval(coolDownInterval);
          setWasCodeResent(false);
          setResendLinkDisabled(false);
        }
      }, 1000);
    });
  };

  return (
    <SendSmsCodeView
      loading={loading}
      status={status}
      code={code}
      triesLeft={triesLeft}
      codeInputRef={codeInputRef}
      inputValue={inputValue}
      maxCodeLength={MAX_CODE_LENGTH}
      wasCodeResent={wasCodeResent}
      coolDown={coolDown}
      resendLinkDisabled={resendLinkDisabled}
      focusCodeInput={focusCodeInput}
      handleCodeInputChange={handleCodeInputChange}
      handleCodeInputKeyPress={handleCodeInputKeyPress}
      handleSendAgainButtonClick={handleSendAgainButtonClick}
    />
  );
};

SendSmsCodeContainer.propTypes = {
  user: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  updateCodeValue: PropTypes.func.isRequired,
  validatePasswordCode: PropTypes.func.isRequired,
  resendPasswordCode: PropTypes.func.isRequired,
};

export default SendSmsCodeContainer;
