import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const ErrorModal = ({ title, message, onCloseClick }) => (
  <Modal
    danger
    title={title}
    actions={[
      {
        label: t('close'),
        danger: true,
        close: true,
        onClick: onCloseClick,
      },
    ]}
  >
    { message && <p>{message}</p> }
  </Modal>
);

ErrorModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  title: '',
  message: undefined,
};

export default ErrorModal;
