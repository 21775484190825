import { Route, Switch } from 'react-router-dom';

import codePasswordChangeReducer from 'SharedComponents/password/ducks';
import sendSMSCodeReducer from './ducks/send_sms_code_duck';
import startPasswordResetReducer from './ducks/start_duck';

import StartPasswordResetApp from './components/start/app';
import SendSMSCodeApp from './components/send_sms_code';
import CodePasswordChangeApp from './components/code_password_change/app';

const { addReducers } = window.reduxState;

addReducers({
  codePasswordChange: codePasswordChangeReducer,
  sendSMSCode: sendSMSCodeReducer,
  startPasswordReset: startPasswordResetReducer,
});

const AppContainer = () => (
  <Switch>
    <Route exact path="/account/password_reset" component={StartPasswordResetApp} />
    <Route exact path="/account/password_reset/send_sms_code" component={SendSMSCodeApp} />
    <Route
      exact
      path={['/account/code_reset', '/:tab/account/reset_password']}
      component={CodePasswordChangeApp}
    />
  </Switch>
);

export default AppContainer;
