export const DEFAULT = '';
export const WRONG_CODE = 'wrong_code';
export const FAILED = 'failed';
export const CODE_WAS_SENT_TOO_MANY_TIMES = 'code_was_sent_too_many_times';
export const SUCCESS = 'success';

export const isValidStatus = (status) => status === SUCCESS;
export const isInvalidStatus = (status) => [
  WRONG_CODE,
  FAILED,
  CODE_WAS_SENT_TOO_MANY_TIMES,
].includes(status);
