import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import {
  minimumCharacters as minLength,
  validateUsernameOrEmailFormat,
} from 'validations';
import EmailOrUsername from './fields/email_or_username';

const inputValidation = (value) => (
  minLength(6, t('javascript.username_error_min_length'))(value)
    || validateUsernameOrEmailFormat()(value)
    || undefined
);

const PasswordResetForm = ({ onSubmit }) => {
  const emailInUse = localStorage.getItem('email_in_use');

  const [initialValues] = useState({
    email_or_username: emailInUse,
  });

  if (emailInUse) {
    localStorage.removeItem('email_in_use');
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, invalid }) => (
        <form className="branded-modal__body" onSubmit={handleSubmit}>
          <ul>
            <Field
              name="email_or_username"
              component={EmailOrUsername}
              validate={inputValidation}
            />
          </ul>
          <div className="password-change-button-wrapper">
            <Button
              label={t('javascript.account.reset_password.change_password')}
              disabled={invalid}
              type="password-reset-button"
            />
          </div>
        </form>
      )}
    </Form>
  );
};

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PasswordResetForm;
