import { connect } from 'react-redux';
import { compose } from 'underscore';
import { withModalWindow } from 'bv-hocs';
import {
  validatePasswordCode,
  updateCodeValue,
  resendPasswordCode,
} from '../../ducks/send_sms_code_duck';
import SendSmsCodeContainer from './send_sms_code_container';

const mapStateToProps = (state) => ({
  user: state.sendSMSCode.user,
  code: state.sendSMSCode.code || '',
  loading: state.sendSMSCode.passwordCodeIsValidating || state.sendSMSCode.passwordCodeIsResending,
});

const mapDispatchToProps = (dispatch) => ({
  validatePasswordCode: (params, onSuccessCallback) => dispatch(validatePasswordCode('/account/password_reset/validate_code', params, onSuccessCallback)),
  resendPasswordCode: (user, onSuccessCallback) => dispatch(resendPasswordCode('/account/reset_password', user, onSuccessCallback)),
  updateCodeValue: compose(dispatch, updateCodeValue),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withModalWindow('change_password', { className: 'password-change-verify-modal' })(SendSmsCodeContainer),
);
